import React, { Component } from 'react';
import Slider from 'react-slick';
import BLUE from '../../assets/blue_circle.svg';
import GREEN from '../../assets/green_circle.svg';
import BLACK from '../../assets/black_circle.svg';
import ORANGE from '../../assets/orange_circle.svg';
import JAPANESE_CAR from '../../assets/img_japanese_large.png';
import MEHRAN from '../../assets/img_mehran_large.png';
import CIVIC from '../../assets/img_civic_large.png';
import COROLLA from '../../assets/img_corolla_small.png';
import './index.css';
import { Fragment } from 'react';
import { EnglishKeys } from '../../language/EN';
import { UrduKeys } from '../../language/UR';
import NEXT_ARROW from '../../assets/icon_arrow_right_black.svg';
import PREV_ARROW from '../../assets/icon_arrow_left_black.svg';

function SampleNextArrow(props) {
	const { className, style, onClick } = props;
	return (
		<img
			src={NEXT_ARROW}
			className={className}
			style={{
				...style,
			}}
			onClick={onClick}
		/>
	);
}

function SamplePrevArrow(props) {
	const { className, style, onClick } = props;
	return (
		<img
			src={PREV_ARROW}
			className={className}
			style={{
				...style,
			}}
			onClick={onClick}
		/>
	);
}

export default class CarCarousel extends React.Component {
	state = {
		slideIndex: 0,
		updateCount: 0,
	};

	render() {
		const { activeLanguage } = this.props;

		const labels = [
			{
				EN: 'Budget',
				UR: 'کفایت',
			},
			{
				EN: 'Comfort',
				UR: 'سہولت',
			},
			{
				EN: 'Business',
				UR: 'بزنس',
			},
			{
				EN: 'Premium',
				UR: 'پریمیم معیار',
			},
		];
		const content = [
			{
				imageSrc: MEHRAN,
				backgroundSrc: GREEN,
			},
			{
				imageSrc: JAPANESE_CAR,
				backgroundSrc: ORANGE,
			},

			{
				imageSrc: COROLLA,
				backgroundSrc: BLUE,
			},
			{
				imageSrc: CIVIC,
				backgroundSrc: BLACK,
			},
		];

		const fontFamily = activeLanguage === 2 ? 'urdu-regular' : '';

		const settings = {
			dots: false,
			speed: 300,
			slidesToShow: 3,
			slidesToScroll: 1,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
					breakpoint: 800,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
			beforeChange: (current, next) => this.setState({ slideIndex: next }),
		};
		return (
			<Fragment>
				<div className="car_carousel_heading">
					<h3 style={{ fontFamily }}>
						{activeLanguage === 1
							? EnglishKeys.car_carousel_heading.heading
							: UrduKeys.car_carousel_heading.heading}
					</h3>
				</div>
				<div className="slider_container">
					<div>
						<Slider
							arrows={true}
							centerMode={true}
							draggable={false}
							ref={(slider) => (this.slider = slider)}
							{...settings}
						>
							{content.map((item, index) => (
								<div key={index} className="item_">
									<img className="nam_nam_2" src={item.imageSrc} />
									{this.state.slideIndex === index && (
										<img className="nam_nam_1" src={item.backgroundSrc} />
									)}
								</div>
							))}
						</Slider>
					</div>
					<div className="pagination_container">
						{labels.map((label, index) => (
							<div
								onClick={() => {
									this.slider.slickGoTo(index);
								}}
								style={{
									textAlign: 'right',
									color: index === this.state.slideIndex ? '#006400' : '#04210E',
									fontWeight: index === this.state.slideIndex && 'bold',
								}}
							>
								<span>{label.EN}</span>
								{index === this.state.slideIndex && <hr className="line" />}
								<span style={{ fontFamily: 'urdu-regular' }}>{label.UR}</span>
							</div>
						))}
					</div>
				</div>
			</Fragment>
		);
	}
}
