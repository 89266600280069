import React, { useState, useEffect } from 'react';
import './index.css';
import BLINK_LOGO from '../../assets/images/icon_logo_white.svg';
import GREEN_LOGO from '../../assets/images/icon_logo_green.svg';
import Carousel from 'react-bootstrap/Carousel';
import IMAGE_1 from '../../assets/images/slideshow/img_background_slide_one.png';
import IMAGE_2 from '../../assets/images/slideshow/img_background_slide_two.png';
import IMAGE_3 from '../../assets/images/slideshow/img_background_slide_three.png';
import IMAGE_4 from '../../assets/images/slideshow/img_background_slide_four.png';
import IMAGE_5 from '../../assets/images/slideshow/img_background_slide_five.png';
import IMAGE_6 from '../../assets/images/slideshow/img_background_slide_six.png';
import PLAYSTORE_ICON from '../../assets/images/footer/apple store.png';
import APPLE_STORE from '../../assets/images/footer/icon_google_play.png';
import { EnglishKeys } from '../../language/EN';
import { UrduKeys } from '../../language/UR';

const HeaderAndCarousel = ({ handleLanguageChange, activeLanguage }) => {
	const [navBgColorClass, setNavBgColorClass] = useState('header_');
	const carouselSrcs = [IMAGE_1, IMAGE_2, IMAGE_3, IMAGE_4, IMAGE_5, IMAGE_6];
	const [activeTextColor, setActiveTextColor] = useState('white');
	const [activeSlide, setactiveSlide] = useState(0);
	const [showHeader, setShowHeader] = useState(true);
	var prevScrollpos = window.pageYOffset;
	const listenScrollEvent = (event) => {
		var currentScrollPos = window.pageYOffset;
		if (prevScrollpos > currentScrollPos) {
			// document.getElementById('navbar').style.top = '0';
			setShowHeader(true);
		} else {
			// document.getElementById('navbar').style.top = '-50px';
			setShowHeader(false);
		}
		prevScrollpos = currentScrollPos;
		if (window.scrollY < 600) {
			return setNavBgColorClass('header_');
		} else if (window.scrollY > 600) {
			return setNavBgColorClass('header_  whitebg');
		}
	};

	useEffect(() => {
		window.addEventListener('scroll', listenScrollEvent);
		let interval = setInterval(() => setactiveSlide((currentSlide) => (currentSlide + 1) % 6), 5000);
		return () => {
			window.removeEventListener('scroll', listenScrollEvent);
			clearInterval(interval);
		};
	}, []);
	useEffect(() => {
		if ([2, 4, 5].includes(activeSlide)) {
			setActiveTextColor('black');
		} else setActiveTextColor('white');
	}, [activeSlide]);
	return (
		<div className="header_carousel_container">
			<div
				className={navBgColorClass}
				style={{
					marginTop: showHeader ? '0' : '-150px',
					flexDirection: activeLanguage === 2 ? 'row-reverse' : '',
				}}
			>
				<img src={navBgColorClass === 'header_' ? BLINK_LOGO : GREEN_LOGO} />
				<div className="lang_div">
					<span
						onClick={() => handleLanguageChange(1)}
						style={{
							color:
								activeLanguage === 1 ? '#006400' : navBgColorClass === 'header_' ? 'white' : '#9B9B9B',
						}}
					>
						EN
					</span>
					<span
						style={{
							color: navBgColorClass === 'header_' ? 'white' : '#9B9B9B',
							marginLeft: 5,
							marginRight: 5,
						}}
					>
						|
					</span>
					<span
						style={{
							color:
								activeLanguage === 2 ? '#006400' : navBgColorClass === 'header_' ? 'white' : '#9B9B9B',
						}}
						onClick={() => handleLanguageChange(2)}
					>
						UR
					</span>
				</div>
			</div>
			<div
				style={{ transition: `all 0.3s` }}
				className={activeLanguage == 1 ? 'text_on_carousel' : 'text_on_carousel _rightSide_text_on_carousel'}
			>
				<h3 style={{ color: activeTextColor }}>
					{activeLanguage === 1
						? EnglishKeys.header_carousel_container.heading
						: UrduKeys.header_carousel_container.heading}
				</h3>
				<p
					className="text_on_carousel_p1"
					style={{
						color: activeTextColor,
						textTransform: 'uppercase',
						fontWeight: 'bold',
					}}
				>
					{activeLanguage === 1
						? EnglishKeys.header_carousel_container.text1
						: UrduKeys.header_carousel_container.text1}
				</p>
				<p
					className="text_on_carousel_p2"
					style={{
						color: activeTextColor,
						fontWeight: 'roboto-light',
						maxWidth: '70%',
						display: activeLanguage === 2 ? 'none' : '',
					}}
				>
					{EnglishKeys.header_carousel_container.text2}
				</p>
				<span>
					<img src={APPLE_STORE} onClick={() => {
                        const url =
                            "https://play.google.com/store/apps/details?id=apps.blink.user";
                        window.open(url, "_blank");
                    }} width="150" />
					<img src={PLAYSTORE_ICON} width="150" />
				</span>
			</div>
			<div className={'carousel_container'}>
				<Carousel activeIndex={activeSlide} fade={true} controls={false}>
					{carouselSrcs.map((carouselSrc, index) => {
						return (
							<Carousel.Item>
								<img className="carousel_img" src={carouselSrc} alt="slideshow" />
							</Carousel.Item>
						);
					})}
				</Carousel>
			</div>
		</div>
	);
};
export default HeaderAndCarousel;
