export const EnglishKeys = {
  header_carousel_container: {
    heading: "Welcome to the space of enablement",
    text1: "We are enablers, not disruptors.",
    text2:
      "Blink empowers existing players with cutting edge technology, enabling them to increase their revenue velocity with data driven operational efficiencies.",
  },
  car_carousel_heading: {
    heading: "CAR TYPES BLINK OFFERS",
  },
  desc_1_text: {
    h3: "Mobility in a Blink",
    p: `Blink is designed with customer’s convenience in mind. Order your On-Demand ride or engage the same captain for a day with Blink app, conveniently, and economically`,
  },
  desc_2_text_1: {
    h3: "A marketplace for mobility, transportation and travel",
    p: `Blink provides a one stop solution for all things mobility. Ranging from local and
		inter-city travel to rentals throughout Pakistan.`,
  },

  desc_2_text_2: {
    h3: "Transparent Pricing",
    p: `Blink has transparency in it’s core. With our model specifically designed to promote transparency we offer packages with entirely transparent pricing`,
  },
  dwb_text_btn: {
    h3: "Drive with Blink",
    p: "Become a Blink captain and reap benefits of the newest revolution in the mobility space. Retain your own identity, your own logo.",
    btnText: " Sign Up as Driver",
  },
  footer_uper_portion: {
    h3: "Download the app",
    hrefs: [
      "About us",
      "Contact us",
      "Investor Relations",
      "Careers",
      "Blog",
      "Help & Support",
      "Terms of Service",
      "Privacy Policy",
      "Cookie Policy",
    ],
  },
  lower_portion: {
    span: "Follow Us",
    p: "© 2021 Blink Lab, Inc.",
  },
};
