export const UrduKeys = {
	header_carousel_container: {
		heading: 'بلنک مین خوش آمدید',
		text1: 'کسی بھی جغرافیہ میں کسی بھی موقع پرموبلٹی کی خدمات کا سب سے موثر سپلائر',
	},
	car_carousel_heading: {
		heading: 'بلنک کی طرف پیش کردہ کار کی قسمیں',
	},
	desc_1_text: {
		h3: 'موازنہ خریداری',
		p: 'بلنک صحت مند مقابلے اور صارفین کے لئے اختیارات پر یقین رکھتی ہے۔ قیمتوں کے موازنے کا تعین کرنے کے ساتھ ، بلک صارفین کے لئے لامتناہی موقع فراہم کرتی ہے اور ڈرائیوروں کو بھی اپنی قیمتوں کا تعین کرنے کی اجازت دیتی ہے۔',
	},
	desc_2_text_1: {
		h3: 'موبلٹی، ٹرانسپورٹیشن اور سفر کے لئے ایک بازار',
		p: `بلنک فراہم کارتا ہے موبلٹی کے تمام مسیائل کا ایک ہل. مقامی اور شہروں کے ما بین سے لے کر رینٹ تک پورے پاکستان مین سہولیات فراہم کرتا ہے.`,
	},

	desc_2_text_2: {
		h3: 'واضح قیمتین',
		p: `معاملات میں شفافیت بلنک کی بنیاد ہے.ہمارا موڈل شفافیت کو سامنے رکھتے ہوے بنایا گیا ہے. پلیتفارم مین مکمل طور پر واضہ قیمتین اسکی مصال ہے.`,
	},
	dwb_text_btn: {
		h3: 'بلنک کے ساتھ گاڑی چلاین',
		p: 'بلنک ڈرائیور بنیں اور موبلٹی میں نئے انقلاب کے فوائد حاصل کریں',
		btnText: 'بطور ڈرائیور سائن اپ کریں',
	},
	footer_uper_portion: {
		h3: 'ایپ ڈاؤن لوڈ کریں',
		hrefs: [
			'ہمارے بارے میں',
			'رابطہ',
			'سرمایہ کاروں کے تعلقات',
			'کیریئر',
			'بلاگ',
			'ہیلپ اور سپورٹ',
			'سروس کی شرائط',
			'رازداری کی پالیسی',
			'کوکی پالیسی',
		],
	},
	lower_portion: {
		span: 'ہمیں فالو کریں',
		p: '© 2021 بلک لیب ، انکارپوریٹڈ',
	},
};
