import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./index.css";
const TermsAndConditions = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container fluid className="terms-container">
      <Row>
        <Col md={12}>
          <h1 align="center">Terms and Conditions</h1>
        </Col>
      </Row>

      <Row className="row">
        <div class="col-md-12">
          <h2>IN GENERAL</h2>
          <p>
            This document governs your relationship with BLINK access to and use
            of this Website, the App, and the products and services available
            through this Website and App (collectively, the "Services") are
            subject to the following terms, conditions and notices (the "Terms
            of Service").
          </p>
          <p>
            We often refer to ("BLINK") which refers to the website and app. By
            using the Services, you are agreeing to all of the Terms of Service,
            as may be updated by us from time to time. You should check this
            page regularly to take notice of any changes we may have made to the
            Terms of Service.
          </p>
        </div>
      </Row>
      <div class="row">
        <div class="col-md-12">
          <h2>CONDITIONS OF USE</h2>
          <p>
            We want to welcome you to our online Website, and our App. BLINK
            provides our services to you, subject to the following conditions.
            If you visit or shop within BLINK, you accept and agree to adhere to
            these conditions. Please read them carefully and contact us with any
            questions.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h2>ELECTRONIC COMMUNICATIONS</h2>
          <p>
            When you visit BLINK, send e-mails to us or use our communication
            method of the app, you are communicating with us electronically. You
            consent to receive communications from us electronically. We will
            communicate with you by e-mail, push notifications, texts or by
            posting notices on this site. You agree that all agreements,
            notices, disclosures, invoices, and other communications that we
            provide to you electronically, satisfy any legal requirement that
            such communications be in writing.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h2>COPYRIGHT</h2>
          <p>
            All content and apps included on this site, such as text, graphics,
            logos, button icons, images, and audio clips, digital downloads,
            data compilations, and software, is the property of BLINK or its
            content suppliers and protected by international copyright laws. The
            compilation of all content on this site is the exclusive property of
            BLINK, with copyright authorship for this collection by BLINK and
            protected by international copyright laws.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h2>TRADEMARKS</h2>
          <p>
            BLINK trademarks may not be used in connection with any product or
            service that is not affiliated with BLINK in any manner that is
            likely to cause confusion among customers, or in any manner that
            disparages or discredits BLINK. All other trademarks not owned by
            BLINK, or its subsidiaries that appear on this site are the property
            of their respective owners, who may or may not be affiliated with,
            connected to, or sponsored by BLINK or its subsidiaries.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h2>REVIEWS, COMMENTS, EMAILS, AND OTHER CONTENT</h2>
          <p>
            Visitors may post reviews, comments, and other content, and submit
            suggestions, ideas, comments, questions, or other information, so
            long as the content is not illegal, obscene, threatening,
            defamatory, invasive of privacy, infringing upon intellectual
            property rights, or otherwise injurious or objectionable to third
            parties, and does not consist of, or contain software viruses,
            political campaigning, commercial solicitation, chain letters, mass
            mailings, or any form of "spam." You may not use a false e-mail
            address, impersonate any person or entity, or otherwise mislead as
            to the origin of a card or other content. BLINK reserves the right
            (but not the obligation) to remove or edit such content, but does
            not regularly review posted content. If you do post content or
            submit material, and unless we indicate otherwise, you grant BLINK
            and its associates a nonexclusive, royalty-free, perpetual,
            irrevocable, and fully sub licensable right to use, reproduce,
            modify, adapt, publish, translate, create derivative works from,
            distribute, and display such content throughout the world in any
            media. You grant BLINK and its associates and sub licensees the
            right to use the name that you submit in connection with such
            content, if they choose. You represent and warrant that you own or
            otherwise control all of the rights to the content that you post,
            that the content is accurate, that use of the content you supply
            does not violate this policy and will not cause injury to any person
            or entity, and that you will indemnify BLINK or any of its
            associates for all claims resulting from content you supply. BLINK
            has the right, but not the obligation, to monitor and edit or remove
            any activity or content. BLINK takes no responsibility and assumes
            no liability for any content posted by you or any third party.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h2>DISPUTES</h2>
          <p>
            Any dispute relating in any way to your visit to BLINK or to
            products you purchase through BLINK shall be submitted to
            confidential arbitration in the County of Santa Clara, State of
            California, USA, except that, to the extent you have in any manner
            violated or threatened to violate BLINK intellectual property
            rights, BLINK may seek injunctive or other appropriate relief in any
            state or federal court in the State of California, USA, and you
            consent to exclusive jurisdiction and venue in such courts.
            Arbitration under this agreement shall be conducted under the rules
            then prevailing of the American Arbitration Association. The
            arbitrator's award shall be binding and may be entered as a judgment
            in any court of competent jurisdiction. To the fullest extent
            permitted by applicable law, no arbitration under this Agreement
            shall be joined to an arbitration involving any other party subject
            to this Agreement, whether through class arbitration proceedings or
            otherwise.
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h2>SITE POLICIES, MODIFICATION, AND SEVERABILITY</h2>
          <p>
            Please review our other policies on this site. These policies also
            govern your visit to BLINK. We reserve the right to make changes to
            our site, policies, and these Conditions of Use at any time without
            notice. If any of these conditions shall be deemed invalid, void, or
            for any reason unenforceable, that condition shall be deemed
            severable and shall not affect the validity and enforceability of
            any remaining condition.
          </p>
        </div>
      </div>
    </Container>
  );
};
export default TermsAndConditions;
