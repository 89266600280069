import "./index.css";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";

const TermsAndConditionsV2 = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container fluid className="TermsAndConditions">
      <div data-custom-class="body" style={{ width: "80%", margin: "auto" }}>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            align="center"
            className="MsoNormal"
            data-custom-class="title"
            style={{ textAlign: "left", lineHeight: "1.5" }}
          >
            <strong>
              <span style={{ lineHeight: "22.5px", fontSize: "26px" }}>
                <bdt className="block-component" />
                <bdt className="question">TERMS AND CONDITIONS</bdt>
                <bdt className="statement-end-if-in-editor" />
              </span>
            </strong>
          </div>
          <div
            align="center"
            className="MsoNormal"
            data-custom-class="title_1"
            style={{ textAlign: "center", lineHeight: "1.5" }}
          >
            <a name="_7m5b3xg56u7y" />
          </div>
          <div
            align="center"
            className="MsoNormal"
            data-custom-class="subtitle"
            style={{ textAlign: "left", lineHeight: "1.5" }}
          >
            <br />
          </div>
          <div
            align="center"
            className="MsoNormal"
            data-custom-class="subtitle"
            style={{ textAlign: "left", lineHeight: "1.5" }}
          >
            <span
              style={{
                color: "rgb(89, 89, 89)",
                fontSize: "14.6667px",
                textAlign: "justify",
              }}
            >
              <strong>
                Last updated
                <bdt
                  className="block-container question question-in-editor"
                  data-id="b6edb852-3fe3-85b8-1b6e-905891f4aa36"
                  data-type="question"
                >
                  September 25, 2022
                </bdt>
              </strong>
            </span>
            <br />
            <a name="_gm5sejt4p02f" />
          </div>
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <strong>
            <span data-custom-class="heading_1">TABLE OF CONTENTS</span>
          </strong>
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#agreement">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">1. AGREEMENT TO TERMS</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#ip">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                2. INTELLECTUAL PROPERTY
              </span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#userreps">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">3. USER REPRESENTATIONS</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
          <a data-custom-class="link" href="#userreg">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">4. USER REGISTRATION</span>
            </span>
          </a>
          <span style={{ fontSize: "15px" }}>
            <bdt className="statement-end-if-in-editor">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#marketplace">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                5. MARKETPLACE OFFERINGS
              </span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#payment">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                6. PURCHASES AND PAYMENT
              </span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
          <a data-custom-class="link" href="#refunds1">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">7. REFUNDS POLICY</span>
            </span>
          </a>
          <span style={{ fontSize: "15px" }}>
            <span data-custom-class="body_text">
              <bdt className="block-component"></bdt>
            </span>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#prohibited">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                8. PROHIBITED ACTIVITIES
              </span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#ugc">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                9. USER GENERATED CONTRIBUTIONS
              </span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#license">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                10. CONTRIBUTION LICENSE
              </span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
          <a data-custom-class="link" href="#reviews">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                11. GUIDELINES FOR REVIEWS
              </span>
            </span>
          </a>
          <span style={{ fontSize: "15px" }}>
            <bdt className="statement-end-if-in-editor">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
          <a data-custom-class="link" href="#mobile">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                12. MOBILE APPLICATION LICENSE
              </span>
            </span>
          </a>
          <span style={{ fontSize: "15px" }}>
            <bdt className="statement-end-if-in-editor">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
          <a data-custom-class="link" href="#socialmedia">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">13. SOCIAL MEDIA</span>
            </span>
          </a>
          <span style={{ fontSize: "15px" }}>
            <bdt className="statement-end-if-in-editor">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#submissions">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">14. SUBMISSIONS</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
          <a data-custom-class="link" href="#thirdparty">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                15. THIRD-PARTY WEBSITES AND CONTENT
              </span>
            </span>
          </a>
          <span style={{ fontSize: "15px" }}>
            <bdt className="statement-end-if-in-editor">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
          <a data-custom-class="link" href="#advertisers">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">16. ADVERTISERS</span>
            </span>
          </a>
          <span style={{ fontSize: "15px" }}>
            <bdt className="statement-end-if-in-editor">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#sitemanage">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">17. SITE MANAGEMENT</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
          <a data-custom-class="link" href="#privacypolicy1">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">18. PRIVACY POLICY</span>
            </span>
          </a>
          <span style={{ fontSize: "15px" }}>
            <span data-custom-class="body_text">
              <bdt className="statement-end-if-in-editor" />
              <bdt className="block-component"></bdt>
            </span>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <bdt className="block-component">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text" />
            </span>
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component">
              <span data-custom-class="body_text"></span>
            </bdt>
            <bdt className="block-component" />
          </span>
          <bdt className="statement-end-if-in-editor">
            <span data-custom-class="body_text" />
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#term">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                19. TERM AND TERMINATION
              </span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#modifications">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                20. MODIFICATIONS AND INTERRUPTIONS
              </span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#law">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">21. GOVERNING LAW</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#disputes">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">22. DISPUTE RESOLUTION</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#corrections">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">23. CORRECTIONS</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#disclaimer">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">24. DISCLAIMER</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#liability">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                25. LIMITATIONS OF LIABILITY
              </span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#indemnification">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">26. INDEMNIFICATION</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#userdata">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">27. USER DATA</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#electronic">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">
                28. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
              </span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <span style={{ fontSize: "15px" }}>
            <bdt className="block-component">
              <span data-custom-class="body_text" />
            </bdt>
          </span>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#misc">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">29. MISCELLANEOUS</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <a data-custom-class="link" href="#contact">
            <span style={{ fontSize: "15px" }}>
              <span data-custom-class="body_text">30. CONTACT US</span>
            </span>
          </a>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="agreement"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_6aa3gkhykvst" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: "19px",
                }}
              >
                1. AGREEMENT TO TERMS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "115%" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <div
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166,
                    msoStyleTextfillFillThemecolor: "text1",
                    msoStyleTextfillFillColortransforms:
                      '"lumm=65000 lumo=35000"',
                  }}
                >
                  <span style={{ fontSize: "14.6667px" }}>
                    These Terms of Use constitute a legally binding agreement
                    made between you, whether personally or on behalf of an
                    entity (“you”) and{" "}
                  </span>
                  <bdt
                    className="block-container question question-in-editor"
                    data-id="9d459c4e-c548-e5cb-7729-a118548965d2"
                    data-type="question"
                    style={{ fontSize: "14.6667px" }}
                  >
                    INNOVATION LAB (PVT) LIMTIED
                  </bdt>
                  <span style={{ fontSize: "14.6667px" }}>
                    <bdt className="block-component" />, doing business as
                    <bdt className="question">BLINK</bdt>
                    <bdt className="statement-end-if-in-editor" />
                    ("
                    <bdt className="block-component" />
                    <bdt className="question">
                      <strong>BLINK</strong>
                    </bdt>
                    <bdt className="else-block" />
                    ", “<strong>we</strong>”, “<strong>us</strong>”, or “
                    <strong>our</strong>”), concerning your access to and use of
                    the
                  </span>
                  <bdt
                    className="block-container question question-in-editor"
                    data-id="fdf30452-99b8-c68b-5cdf-34af764cd1fd"
                    data-type="question"
                    style={{ fontSize: "14.6667px" }}
                  >
                    <a
                      href="https://eblink.io"
                      target="_blank"
                      data-custom-class="link"
                    >
                      https://eblink.io
                    </a>
                  </bdt>
                  <span style={{ fontSize: "14.6667px" }}> website</span> as
                  well as any other media form, media channel, mobile website or
                  mobile application related, linked, or otherwise connected
                  thereto (collectively, the “Site”).
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      msoFareastFontFamily: "Calibri",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        msoFareastFontFamily: "Calibri",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11.0pt",
                          lineHeight: "115%",
                          msoFareastFontFamily: "Calibri",
                          color: "#595959",
                          msoThemecolor: "text1",
                          msoThemetint: 166,
                        }}
                      >
                        <bdt className="question">
                          <bdt className="block-component" />
                        </bdt>
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            msoFareastFontFamily: "Calibri",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          <bdt className="block-component" />
                        </span>
                        We are registered in
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                        <span
                          style={{
                            fontSize: "11.0pt",
                            lineHeight: "115%",
                            msoFareastFontFamily: "Calibri",
                            color: "#595959",
                            msoThemecolor: "text1",
                            msoThemetint: 166,
                          }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              msoFareastFontFamily: "Calibri",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            <bdt className="question">Pakistan</bdt>
                            <bdt className="statement-end-if-in-editor">
                              <span
                                style={{
                                  fontSize: "11.0pt",
                                  lineHeight: "115%",
                                  msoFareastFontFamily: "Calibri",
                                  color: "#595959",
                                  msoThemecolor: "text1",
                                  msoThemetint: 166,
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: "11.0pt",
                                    lineHeight: "115%",
                                    msoFareastFontFamily: "Calibri",
                                    color: "#595959",
                                    msoThemecolor: "text1",
                                    msoThemetint: 166,
                                  }}
                                >
                                  <bdt className="statement-end-if-in-editor" />
                                </span>
                              </span>
                            </bdt>
                          </span>
                        </span>
                        and have our registered office at
                        <bdt className="question">
                          G-3, Jouhar belle view apartment, block 14, Gulistan e
                          jouhar
                        </bdt>
                        <bdt className="block-component" />
                      </span>
                    </span>
                    ,<bdt className="question">Karachi</bdt>
                  </span>
                </span>
                <span style={{ fontSize: "15px" }}>
                  <span data-custom-class="body_text">
                    <span style={{ fontSize: "15px" }}>
                      <span data-custom-class="body_text">
                        <span style={{ color: "rgb(89, 89, 89)" }}>
                          <bdt className="block-component" />,
                          <bdt className="question">Sindh</bdt>
                          <bdt className="statement-end-if-in-editor" />
                          <bdt className="block-component" />
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    msoFareastFontFamily: "Calibri",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166,
                  }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      msoFareastFontFamily: "Calibri",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <bdt className="statement-end-if-in-editor">.</bdt>
                    <bdt className="else-block" />
                  </span>
                </span>
                <bdt className="statement-end-if-in-editor" />
                <bdt className="block-component" /> The Site provides an online
                marketplace for the following goods, products, and/or services:{" "}
                <bdt
                  className="block-container question question-in-editor"
                  data-id="caae56ce-b84c-652b-3ded-c519b4c7c620"
                  data-type="question"
                >
                  Mobility and Transportation services
                </bdt>{" "}
                (the “Marketplace Offerings”). In order to help make the Site a
                secure environment for the purchase and sale of Marketplace
                Offerings, all users are required to accept and comply with
                these Terms of Use
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="6ea0fd2b-046d-3d22-6a3d-c59d0c7f9704"
                >
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="separate_agreement_option"
                      data-type="statement"
                    />
                  </bdt>
                  . You agree that by accessing the Site and/or the Marketplace
                  Offerings, you have read, understood, and agree to be bound by
                  all of these Terms of Use. IF YOU DO NOT AGREE WITH ALL OF
                  THESE TERMS OF USE, THEN YOU ARE EXPRESSLY PROHIBITED FROM
                  USING THE SITE AND/OR THE MARKETPLACE OFFERINGS AND YOU MUST
                  DISCONTINUE USE IMMEDIATELY.
                </bdt>
              </div>
              <div
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: 1, textAlign: "left" }}
              >
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166,
                    msoStyleTextfillFillThemecolor: "text1",
                    msoStyleTextfillFillColortransforms:
                      '"lumm=65000 lumo=35000"',
                  }}
                >
                  <br />
                </span>
              </div>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              Supplemental terms and conditions or documents that may be posted
              on the Site from time to time are hereby expressly incorporated
              herein by reference. We reserve the right, in our sole discretion,
              to make changes or modifications to these Terms of Use
              <bdt className="block-component" />
              at any time and for any reason
              <bdt className="statement-end-if-in-editor" />. We will alert you
              about any changes by updating the “Last updated” date of these
              Terms of Use, and you waive any right to receive specific notice
              of each such change. Please ensure that you check the applicable
              Terms every time you use our Site so that you understand which
              Terms apply. You will be subject to, and will be deemed to have
              been made aware of and to have accepted, the changes in any
              revised Terms of Use by your continued use of the Site after the
              date such revised Terms of Use are posted.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              The information provided on the Site is not intended for
              distribution to or use by any person or entity in any jurisdiction
              or country where such distribution or use would be contrary to law
              or regulation or which would subject us to any registration
              requirement within such jurisdiction or country. Accordingly,
              those persons who choose to access the Site from other locations
              do so on their own initiative and are solely responsible for
              compliance with local laws, if and to the extent local laws are
              applicable.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <bdt className="block-component" />
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <bdt
              className="block-container if"
              data-type="if"
              id="a2595956-7028-dbe5-123e-d3d3a93ed076"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_o18_option"
                  data-type="statement"
                />
                <bdt data-type="body">
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    The Site is intended for users who are at least 18 years
                    old. Persons under the age of 18 are not permitted to use or
                    register for the Site or use the Marketplace Offerings.
                  </span>
                </bdt>
              </bdt>
              <bdt
                className="block-container if"
                data-type="if"
                id="a2595956-7028-dbe5-123e-d3d3a93ed076"
              >
                <bdt data-type="conditional-block">
                  <bdt className="block-component" />
                </bdt>
              </bdt>
            </bdt>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="ip"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_b6y29mp52qvx" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: "19px",
                }}
              >
                2. INTELLECTUAL PROPERTY RIGHTS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              Unless otherwise indicated, the Site and the Marketplace Offerings
              are our proprietary property and all source code, databases,
              functionality, software, website designs, audio, video, text,
              photographs, and graphics on the Site (collectively, the
              “Content”) and the trademarks, service marks, and logos contained
              therein (the “Marks”) are owned or controlled by us or licensed to
              us, and are protected by copyright and trademark laws and various
              other intellectual property rights and unfair competition laws of
              the United States, international copyright laws, and international
              conventions. The Content and the Marks are provided on the Site
              “AS IS” for your information and personal use only. Except as
              expressly provided in these Terms of Use, no part of the Site or
              the Marketplace Offerings and no Content or Marks may be copied,
              reproduced, aggregated, republished, uploaded, posted, publicly
              displayed, encoded, translated, transmitted, distributed, sold,
              licensed, or otherwise exploited for any commercial purpose
              whatsoever, without our express prior written permission.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              Provided that you are eligible to use the Site, you are granted a
              limited license to access and use the Site and to download or
              print a copy of any portion of the Content to which you have
              properly gained access solely for your personal, non-commercial
              use. We reserve all rights not expressly granted to you in and to
              the Site, the Content and the Marks.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="userreps"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_5hg7kgyv9l8z" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: "19px",
                }}
              >
                3. USER REPRESENTATIONS
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              By using the Site or the Marketplace Offerings, you represent and
              warrant that:
            </span>
            <bdt
              className="block-container if"
              data-type="if"
              id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="user_account_option"
                  data-type="statement"
                />{" "}
                <bdt data-type="body">
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                    (
                  </span>
                  <span
                    style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                  >
                    1
                  </span>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                    ) all registration information you submit will be true,
                    accurate, current, and complete; (
                  </span>
                  <span
                    style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                  >
                    2
                  </span>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                    ) you will maintain the accuracy of such information and
                    promptly update such registration information as necessary;
                  </span>
                </bdt>
              </bdt>
              <bdt
                className="block-container if"
                data-type="if"
                id="d2d82ca8-275f-3f86-8149-8a5ef8054af6"
              >
                <bdt className="statement-end-if-in-editor" data-type="close" />{" "}
              </bdt>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                3
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) you have the legal capacity and you agree to comply with these
                Terms of Use;
              </span>
              <bdt
                className="block-container if"
                data-type="if"
                id="8d4c883b-bc2c-f0b4-da3e-6d0ee51aca13"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="user_u13_option"
                    data-type="statement"
                  />
                </bdt>
              </bdt>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                {" "}
                (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                4
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) you are not a minor in the jurisdiction in which you reside
                <bdt
                  className="block-container if"
                  data-type="if"
                  id="76948fab-ec9e-266a-bb91-948929c050c9"
                >
                  <bdt data-type="conditional-block">
                    <bdt
                      className="block-component"
                      data-record-question-key="user_o18_option"
                      data-type="statement"
                    />
                  </bdt>
                  ; (
                </bdt>
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                5
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) you will not access the Site or the Marketplace Offerings
                through automated or non-human means, whether through a bot,
                script or otherwise; (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                6
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) you will not use the Site for any illegal or unauthorized
                purpose; and (
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}>
                7
              </span>
              <span style={{ color: "rgb(89, 89, 89)", fontSize: "11pt" }}>
                ) your use of the Site or the Marketplace Offerings will not
                violate any applicable law or regulation.
              </span>
              <span
                style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
              />
            </bdt>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              If you provide any information that is untrue, inaccurate, not
              current, or incomplete, we have the right to suspend or terminate
              your account and refuse any and all current or future use of the
              Site (or any portion thereof).
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: 1 }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <br />
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ textAlign: "justify", lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <div
                className="MsoNormal"
                data-custom-class="body_text"
                style={{ lineHeight: "1.5", textAlign: "left" }}
              >
                <span
                  style={{
                    fontSize: "11.0pt",
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    color: "#595959",
                    msoThemecolor: "text1",
                    msoThemetint: 166,
                  }}
                >
                  You may not use the Site or the Marketplace Offerings for any
                  illegal or unauthorized purpose nor may you, in the use of
                  Marketplace Offerings, violate any laws. Among unauthorized
                  Marketplace Offerings are the following: intoxicants of any
                  sort; illegal drugs or other illegal products; alcoholic
                  beverages; games of chance; and pornography or graphic adult
                  content, images, or other adult products. Postings of any
                  unauthorized products or content may result in immediate
                  termination of your account and a lifetime ban from use of the
                  Site.
                </span>
              </div>
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ textAlign: "justify", lineHeight: 1 }}
          >
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <span style={{ fontSize: "11pt" }}>
                We are a service provider and make no representations as to the
                safety, effectiveness, adequacy, accuracy, availability, prices,
                ratings, reviews, or legality of any of the information
                contained on the Site or the Marketplace Offerings displayed or
                offered through the Site. You understand and agree that the
                content of the Site does not contain or constitute
                representations to be reasonably relied upon, and you agree to
                hold us harmless from any errors, omissions, or
                misrepresentations contained within the Site’s content. We do
                not endorse or recommend any Marketplace Offerings and the Site
                is provided for informational and advertising purposes only.
              </span>
              <br />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a name="_19c0jyrfb9ll" />
          </div>
          <bdt
            className="block-container if"
            data-type="if"
            id="d13abc62-cc6f-e059-7fd6-cb6208085aa7"
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="user_account_option"
                data-type="statement"
              />
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="userreg"
                  style={{ lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      4. USER REGISTRATION
                    </span>
                  </strong>
                </div>
              </bdt>
            </bdt>
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <bdt className="block-container if" data-type="if">
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    You may be required to register with the Site in order to
                    access the Marketplace Offerings. You agree to keep your
                    password confidential and will be responsible for all use of
                    your account and password. We reserve the right to remove,
                    reclaim, or change a username you select if we determine, in
                    our sole discretion, that such username is inappropriate,
                    obscene, or otherwise objectionable.
                  </span>
                </div>
              </bdt>
            </bdt>
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <bdt
            className="block-container if"
            data-type="if"
            id="d13abc62-cc6f-e059-7fd6-cb6208085aa7"
          >
            <bdt className="statement-end-if-in-editor" data-type="close" />
          </bdt>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="marketplace"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_nds4qylockxx" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: "19px",
                }}
              >
                5. MARKETPLACE OFFERINGS
              </span>
            </strong>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <span
              style={{
                color: "rgb(89, 89, 89)",
                fontSize: "14.6667px",
                textAlign: "justify",
              }}
            >
              <br />
            </span>
          </div>
          <bdt
            className="block-container if"
            data-type="if"
            id="872e506c-a3ee-71b4-cdbf-a6345258c228"
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="product_option"
                data-type="statement"
              />
            </bdt>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: "1.5" }}
            >
              <span
                style={{
                  fontSize: "11.0pt",
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  color: "#595959",
                  msoThemecolor: "text1",
                  msoThemetint: 166,
                }}
              >
                We reserve the right to limit the quantities of the Marketplace
                Offerings offered or available on the Site. All descriptions or
                pricing of the Marketplace Offerings are subject to change at
                any time without notice, at our sole discretion. We reserve the
                right to discontinue any Marketplace Offerings at any time for
                any reason. We do not warrant that the quality of any of the
                Marketplace Offerings purchased by you will meet your
                expectations or that any errors in the Site will be corrected.
              </span>
            </div>
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div
            className="MsoNormal"
            data-custom-class="heading_1"
            id="payment"
            style={{ lineHeight: "1.5" }}
          >
            <a name="_ynub0jdx8pob" />
            <strong>
              <span
                style={{
                  lineHeight: "115%",
                  fontFamily: "Arial",
                  fontSize: "19px",
                }}
              >
                6. PURCHASES AND PAYMENT
              </span>
            </strong>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We accept the following forms of payment:
            </span>
          </div>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "115%" }}
          >
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{ lineHeight: 1, textAlign: "left" }}
            >
              <br />
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{
                marginLeft: "46.9pt",
                textIndent: "-18.55pt",
                lineHeight: "1.5",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <bdt
                  className="block-container forloop"
                  data-type="forloop"
                  id="a6e21b8e-b955-c546-15dd-bb6eda2fe88a"
                >
                  <bdt data-type="conditional-block">
                    <bdt
                      className="forloop-component"
                      data-record-question-key="payment_list"
                      data-type="statement"
                    />
                    <bdt data-type="body">
                      <span
                        style={{
                          color: "rgb(89, 89, 89)",
                          fontSize: "14.6667px",
                          textAlign: "justify",
                          textIndent: "-24.7333px",
                        }}
                      >
                        - Visa
                      </span>
                    </bdt>
                  </bdt>
                </bdt>
              </span>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{
                marginLeft: "46.9pt",
                textIndent: "-18.55pt",
                lineHeight: "1.5",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <bdt className="block-container forloop" data-type="forloop">
                  <bdt className="for-end-in-editor" data-type="close" />
                  <bdt data-type="body">
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "14.6667px",
                        textAlign: "justify",
                        textIndent: "-24.7333px",
                      }}
                    >
                      - Mastercard
                    </span>
                  </bdt>
                </bdt>
              </span>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{
                marginLeft: "46.9pt",
                textIndent: "-18.55pt",
                lineHeight: "1.5",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <bdt className="block-container forloop" data-type="forloop">
                  <bdt className="for-end-in-editor" data-type="close" />
                  <bdt data-type="body">
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "14.6667px",
                        textAlign: "justify",
                        textIndent: "-24.7333px",
                      }}
                    >
                      - American Express
                    </span>
                  </bdt>
                </bdt>
              </span>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{
                marginLeft: "46.9pt",
                textIndent: "-18.55pt",
                lineHeight: "1.5",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <bdt className="block-container forloop" data-type="forloop">
                  <bdt className="for-end-in-editor" data-type="close" />
                  <bdt data-type="body">
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "14.6667px",
                        textAlign: "justify",
                        textIndent: "-24.7333px",
                      }}
                    >
                      - PayPal
                    </span>
                  </bdt>
                </bdt>
              </span>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{
                marginLeft: "46.9pt",
                textIndent: "-18.55pt",
                lineHeight: "1.5",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <bdt className="block-container forloop" data-type="forloop">
                  <bdt className="for-end-in-editor" data-type="close" />
                  <bdt data-type="body">
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "14.6667px",
                        textAlign: "justify",
                        textIndent: "-24.7333px",
                      }}
                    >
                      - easypaisa
                    </span>
                  </bdt>
                </bdt>
              </span>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{
                marginLeft: "46.9pt",
                textIndent: "-18.55pt",
                lineHeight: "1.5",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <bdt className="block-container forloop" data-type="forloop">
                  <bdt className="for-end-in-editor" data-type="close" />
                  <bdt data-type="body">
                    <span
                      style={{
                        color: "rgb(89, 89, 89)",
                        fontSize: "14.6667px",
                        textAlign: "justify",
                        textIndent: "-24.7333px",
                      }}
                    >
                      - Jazzcash
                    </span>
                  </bdt>
                </bdt>
              </span>
            </div>
            <div
              className="MsoNormal"
              data-custom-class="body_text"
              style={{
                marginLeft: "46.9pt",
                textIndent: "-18.55pt",
                lineHeight: "1.5",
                textAlign: "left",
              }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  lineHeight: "16.8667px",
                  color: "rgb(89, 89, 89)",
                }}
              >
                <bdt className="block-container forloop" data-type="forloop">
                  <bdt className="for-end-in-editor" data-type="close" />
                </bdt>
              </span>
            </div>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: 1 }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <br />
            </span>
          </div>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              You agree to provide current, complete, and accurate purchase and
              account information for all purchases of the Marketplace Offerings
              made via the Site. You further agree to promptly update account
              and payment information, including email address, payment method,
              and payment card expiration date, so that we can complete your
              transactions and contact you as needed. Sales tax will be added to
              the price of purchases as deemed required by us. We may change
              prices at any time. All payments shall be in{" "}
              <bdt className="question">PKR</bdt>.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              You agree to pay all charges at the prices then in effect for your
              purchases and any applicable shipping fees, and you authorize us
              to charge your chosen payment provider for any such amounts upon
              placing your order.
              <bdt
                className="block-container if"
                data-type="if"
                id="9c0216a1-d094-fd73-a062-9615dc795ffc"
              >
                <bdt data-type="conditional-block">
                  <bdt
                    className="block-component"
                    data-record-question-key="recurring_charge_option"
                    data-type="statement"
                  />
                </bdt>
                We reserve the right to correct any errors or mistakes in
                pricing, even if we have already requested or received payment.
              </bdt>
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              We reserve the right to refuse any order placed through the Site.
              We may, in our sole discretion, limit or cancel quantities
              purchased per person, per household, or per order. These
              restrictions may include orders placed by or under the same
              customer account, the same payment method, and/or orders that use
              the same billing or shipping address. We reserve the right to
              limit or prohibit orders that, in our sole judgment, appear to be
              placed by dealers, resellers, or distributors.
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              <br />
            </span>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt
              className="block-container if"
              data-type="if"
              id="50509925-8056-3ba8-f25b-851ea4e25020"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="refund_services_option"
                  data-type="statement"
                />
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="refunds1"
                    style={{ lineHeight: "1.5" }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        7. REFUNDS POLICY
                      </span>
                    </strong>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: 1 }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt className="block-container if" data-type="if">
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      <span style={{ fontSize: "11pt", textAlign: "left" }}>
                        Please review our Return Policy posted on the Site prior
                        to making any purchases.
                      </span>
                    </span>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <br />
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <bdt
              className="block-container if"
              data-type="if"
              id="50509925-8056-3ba8-f25b-851ea4e25020"
            >
              <bdt data-type="conditional-block">
                <bdt
                  className="block-component"
                  data-record-question-key="refund_services_option"
                  data-type="statement"
                />
              </bdt>
            </bdt>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a name="_cjgb09up4gis" />
          </div>
          <bdt
            className="block-container if"
            data-type="if"
            id="16425f29-c2d8-35be-ebbc-311508da6224"
          >
            <bdt data-type="conditional-block">
              <bdt
                className="block-component"
                data-record-question-key="return_option"
                data-type="statement"
              />
            </bdt>
            <div
              className="MsoNormal"
              data-custom-class="heading_1"
              id="prohibited"
              style={{ lineHeight: "1.5" }}
            >
              <a name="_h284p8mrs3r7" />
              <strong>
                <span
                  style={{
                    lineHeight: "115%",
                    fontFamily: "Arial",
                    fontSize: "19px",
                  }}
                >
                  8. PROHIBITED ACTIVITIES
                </span>
              </strong>
            </div>
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            data-custom-class="body_text"
            style={{ lineHeight: "1.5" }}
          >
            <span
              style={{
                fontSize: "11.0pt",
                lineHeight: "115%",
                fontFamily: "Arial",
                color: "#595959",
                msoThemecolor: "text1",
                msoThemetint: 166,
              }}
            >
              You may not access or use the Site for any purpose other than that
              for which we make the Site available. The Site may not be used in
              connection with any commercial endeavors except those that are
              specifically endorsed or approved by us.
            </span>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <div
            className="MsoNormal"
            style={{ textAlign: "justify", lineHeight: "115%" }}
          >
            <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
              <div className="MsoNormal" style={{ lineHeight: "17.25px" }}>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5", textAlign: "left" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      lineHeight: "16.8667px",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    As a user of the Site, you agree not to:
                  </span>
                </div>
                <ul>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "sans-serif",
                          fontSize: "15px",
                          fontStyle: "normal",
                          fontVariantLigatures: "normal",
                          fontVariantCaps: "normal",
                          fontWeight: 400,
                          letterSpacing: "normal",
                          orphans: 2,
                          textAlign: "justify",
                          textIndent: "-29.4px",
                          textTransform: "none",
                          whiteSpace: "normal",
                          widows: 2,
                          wordSpacing: "0px",
                          WebkitTextStrokeWidth: "0px",
                          backgroundColor: "rgb(255, 255, 255)",
                          textDecorationStyle: "initial",
                          textDecorationColor: "initial",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        Systematically retrieve data or other content from the
                        Site to create or compile, directly or indirectly, a
                        collection, compilation, database, or directory without
                        written permission from us.
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Trick, defraud, or mislead us and other users,
                              especially in any attempt to learn sensitive
                              account information such as user passwords.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Circumvent, disable, or otherwise interfere with
                              security-related features of the Site, including
                              features that prevent or restrict the use or
                              copying of any Content or enforce limitations on
                              the use of the Site and/or the Content contained
                              therein.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Disparage, tarnish, or otherwise harm, in our
                              opinion, us and/or the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Use any information obtained from the Site in
                              order to harass, abuse, or harm another person.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Make improper use of our support services or
                              submit false reports of abuse or misconduct.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Use the Site in a manner inconsistent with any
                              applicable laws or regulations.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Engage in unauthorized framing of or linking to
                              the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Upload or transmit (or attempt to upload or to
                              transmit) viruses, Trojan horses, or other
                              material, including excessive use of capital
                              letters and spamming (continuous posting of
                              repetitive text), that interferes with any party’s
                              uninterrupted use and enjoyment of the Site or
                              modifies, impairs, disrupts, alters, or interferes
                              with the use, features, functions, operation, or
                              maintenance of the Marketplace Offerings.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Engage in any automated use of the system, such as
                              using scripts to send comments or messages, or
                              using any data mining, robots, or similar data
                              gathering and extraction tools.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Delete the copyright or other proprietary rights
                              notice from any Content.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Attempt to impersonate another user or person or
                              use the username of another user.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Upload or transmit (or attempt to upload or to
                              transmit) any material that acts as a passive or
                              active information collection or transmission
                              mechanism, including without limitation, clear
                              graphics interchange formats (“gifs”), 1×1 pixels,
                              web bugs, cookies, or other similar devices
                              (sometimes referred to as “spyware” or “passive
                              collection mechanisms” or “pcms”).
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Interfere with, disrupt, or create an undue burden
                              on the Site or the networks or services connected
                              to the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Harass, annoy, intimidate, or threaten any of our
                              employees or agents engaged in providing any
                              portion of the Marketplace Offerings to you.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Attempt to bypass any measures of the Site
                              designed to prevent or restrict access to the
                              Site, or any portion of the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Copy or adapt the Site’s software, including but
                              not limited to Flash, PHP, HTML, JavaScript, or
                              other code.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Except as permitted by applicable law, decipher,
                              decompile, disassemble, or reverse engineer any of
                              the software comprising or in any way making up a
                              part of the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Except as may be the result of standard search
                              engine or Internet browser usage, use, launch,
                              develop, or distribute any automated system,
                              including without limitation, any spider, robot,
                              cheat utility, scraper, or offline reader that
                              accesses the Site, or using or launching any
                              unauthorized script or other software.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Use a buying agent or purchasing agent to make
                              purchases on the Site.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Make any unauthorized use of the Marketplace
                              Offerings, including collecting usernames and/or
                              email addresses of users by electronic or other
                              means for the purpose of sending unsolicited
                              email, or creating user accounts by automated
                              means or under false pretenses.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </li>
                  <li
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5", textAlign: "left" }}
                  >
                    <span style={{ fontSize: "15px" }}>
                      <span
                        style={{
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontStyle: "normal",
                            fontVariantLigatures: "normal",
                            fontVariantCaps: "normal",
                            fontWeight: 400,
                            letterSpacing: "normal",
                            orphans: 2,
                            textAlign: "justify",
                            textIndent: "-29.4px",
                            textTransform: "none",
                            whiteSpace: "normal",
                            widows: 2,
                            wordSpacing: "0px",
                            WebkitTextStrokeWidth: "0px",
                            backgroundColor: "rgb(255, 255, 255)",
                            textDecorationStyle: "initial",
                            textDecorationColor: "initial",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <span
                            style={{
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "sans-serif",
                                fontStyle: "normal",
                                fontVariantLigatures: "normal",
                                fontVariantCaps: "normal",
                                fontWeight: 400,
                                letterSpacing: "normal",
                                orphans: 2,
                                textAlign: "justify",
                                textIndent: "-29.4px",
                                textTransform: "none",
                                whiteSpace: "normal",
                                widows: 2,
                                wordSpacing: "0px",
                                WebkitTextStrokeWidth: "0px",
                                backgroundColor: "rgb(255, 255, 255)",
                                textDecorationStyle: "initial",
                                textDecorationColor: "initial",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              Use the Marketplace Offerings as part of any
                              effort to compete with us or otherwise use the
                              Site and/or the Content for any revenue-generating
                              endeavor or commercial enterprise.
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <bdt className="forloop-component" />
                    </span>
                  </li>
                </ul>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a name="_zbbv9tgty199" />
          </div>
          <bdt
            className="block-container if"
            data-type="if"
            id="24327c5d-a34f-f7e7-88f1-65a2f788484f"
          >
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="ugc"
                  style={{ lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      9. USER GENERATED CONTRIBUTIONS
                    </span>
                  </strong>
                </div>
              </bdt>
            </bdt>
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <bdt className="block-container if" data-type="if">
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <bdt className="block-component" />
                    The Site does not offer users to submit or post content. We
                    may provide you with the opportunity to create, submit,
                    post, display, transmit, perform, publish, distribute, or
                    broadcast content and materials to us or on the Site,
                    including but not limited to text, writings, video, audio,
                    photographs, graphics, comments, suggestions, or personal
                    information or other material (collectively,
                    "Contributions"). Contributions may be viewable by other
                    users of the Site and through third-party websites. As such,
                    any Contributions you transmit may be treated in accordance
                    with the Site Privacy Policy. When you create or make
                    available any Contributions, you thereby represent and
                    warrant that:
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </div>
              </bdt>
            </bdt>
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <bdt className="block-container if" data-type="if">
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div
                  className="MsoNormal"
                  style={{ textAlign: "justify", lineHeight: "115%" }}
                >
                  <ul>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          The creation, distribution, transmission, public
                          display, or performance, and the accessing,
                          downloading, or copying of your Contributions do not
                          and will not infringe the proprietary rights,
                          including but not limited to the copyright, patent,
                          trademark, trade secret, or moral rights of any third
                          party.
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          You are the creator and owner of or have the necessary
                          licenses, rights, consents, releases, and permissions
                          to use and to authorize us, the Site, and other users
                          of the Site to use your Contributions in any manner
                          contemplated by the Site and these Terms of Use.
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          You have the written consent, release, and/or
                          permission of each and every identifiable individual
                          person in your Contributions to use the name or
                          likeness of each and every such identifiable
                          individual person to enable inclusion and use of your
                          Contributions in any manner contemplated by the Site
                          and these Terms of Use.
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          Your Contributions are not false, inaccurate, or
                          misleading.
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          Your Contributions are not unsolicited or unauthorized
                          advertising, promotional materials, pyramid schemes,
                          chain letters, spam, mass mailings, or other forms of
                          solicitation.
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          Your Contributions are not obscene, lewd, lascivious,
                          filthy, violent, harassing, libelous, slanderous, or
                          otherwise objectionable (as determined by us).
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          Your Contributions do not ridicule, mock, disparage,
                          intimidate, or abuse anyone.
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          Your Contributions are not used to harass or threaten
                          (in the legal sense of those terms) any other person
                          and to promote violence against a specific person or
                          class of people.
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          Your Contributions do not violate any applicable law,
                          regulation, or rule.
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          Your Contributions do not violate the privacy or
                          publicity rights of any third party.
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          Your Contributions do not violate any applicable law
                          concerning child pornography, or otherwise intended to
                          protect the health or well-being of minors.
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          Your Contributions do not include any offensive
                          comments that are connected to race, national origin,
                          gender, sexual preference, or physical handicap.
                        </span>
                      </span>
                    </li>
                    <li
                      className="MsoNormal"
                      data-custom-class="body_text"
                      style={{ lineHeight: "1.5", textAlign: "left" }}
                    >
                      <span
                        style={{ color: "rgb(89, 89, 89)", fontSize: "14px" }}
                      >
                        <span data-custom-class="body_text">
                          Your Contributions do not otherwise violate, or link
                          to material that violates, any provision of these
                          Terms of Use, or any applicable law or regulation.
                        </span>
                      </span>{" "}
                    </li>
                  </ul>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    Any use of the Site or the Marketplace Offerings in
                    violation of the foregoing violates these Terms of Use and
                    may result in, among other things, termination or suspension
                    of your rights to use the Site and the Marketplace
                    Offerings.
                  </span>
                </div>
              </bdt>
            </bdt>
          </bdt>
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: "1.5" }}>
          <br />
        </div>
        <div align="center" style={{ textAlign: "left", lineHeight: 1 }}>
          <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
            <a name="_4tmvzsaugzf4" />{" "}
            <bdt
              className="block-container if"
              data-type="if"
              id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709"
            >
              <bdt data-type="conditional-block">
                <bdt data-type="body">
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="license"
                    style={{ lineHeight: "1.5" }}
                  >
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        10. CONTRIBUTION LICENSE
                      </span>
                    </strong>
                  </div>
                </bdt>
              </bdt>
            </bdt>
          </div>
        </div>
        <div align="center" style={{ textAlign: "left" }}>
          <bdt
            className="block-container if"
            data-type="if"
            id="a088ddfb-d8c1-9e58-6f21-958c3f4f0709"
          >
            <bdt data-type="conditional-block">
              <bdt data-type="body">
                <div className="MsoNormal" style={{ lineHeight: 1 }}>
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <bdt className="block-component">
                      <br />
                    </bdt>
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    You and the Site agree that we may access, store, process,
                    and use any information and personal data that you provide
                    following the terms of the Privacy Policy and your choices
                    (including settings).
                  </span>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    By submitting suggestions or other feedback regarding the
                    Site, you agree that we can use and share such feedback for
                    any purpose without compensation to you.
                  </span>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    We do not assert any ownership over your Contributions. You
                    retain full ownership of all of your Contributions and any
                    intellectual property rights or other proprietary rights
                    associated with your Contributions. We are not liable for
                    any statements or representations in your Contributions
                    provided by you in any area on the Site. You are solely
                    responsible for your Contributions to the Site and you
                    expressly agree to exonerate us from any and all
                    responsibility and to refrain from any legal action against
                    us regarding your Contributions.
                  </span>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-container if" data-type="if">
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    />
                  </bdt>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <a name="_s3mgjgvn9g51" />
                  </div>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="a378120a-96b1-6fa3-279f-63d5b96341d3"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="review_option"
                        data-type="statement"
                      />
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          id="reviews"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              11. GUIDELINES FOR REVIEWS
                            </span>
                          </strong>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            We may provide you areas on the Site to leave
                            reviews or ratings. When posting a review, you must
                            comply with the following criteria: (1) you should
                            have firsthand experience with the person/entity
                            being reviewed; (2) your reviews should not contain
                            offensive profanity, or abusive, racist, offensive,
                            or hate language; (3) your reviews should not
                            contain discriminatory references based on religion,
                            race, gender, national origin, age, marital status,
                            sexual orientation, or disability; (4) your reviews
                            should not contain references to illegal activity;
                            (5) you should not be affiliated with competitors if
                            posting negative reviews; (6) you should not make
                            any conclusions as to the legality of conduct; (7)
                            you may not post any false or misleading statements;
                            and (8) you may not organize a campaign encouraging
                            others to post reviews, whether positive or
                            negative.
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            We may accept, reject, or remove reviews in our sole
                            discretion. We have absolutely no obligation to
                            screen reviews or to delete reviews, even if anyone
                            considers reviews objectionable or inaccurate.
                            Reviews are not endorsed by us, and do not
                            necessarily represent our opinions or the views of
                            any of our affiliates or partners. We do not assume
                            liability for any review or for any claims,
                            liabilities, or losses resulting from any review. By
                            posting a review, you
                          </span>
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              msoFareastFontFamily: "Arial",
                            }}
                          >
                            {" "}
                          </span>
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              msoFareastFontFamily: "Calibri",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            hereby grant to us a perpetual, non-exclusive,
                            worldwide, royalty-free, fully-paid, assignable, and
                            sublicensable right and license to reproduce,
                            modify, translate, transmit by any means, display,
                            perform, and/or distribute all content relating to
                            reviews.
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="a378120a-96b1-6fa3-279f-63d5b96341d3"
                  >
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    />
                  </bdt>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <a name="_6nl7u6ag6use" />
                  </div>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="mobile_app_option"
                        data-type="statement"
                      />
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          id="mobile"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              12. MOBILE APPLICATION LICENSE
                            </span>
                          </strong>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                          <a name="_4p0l96wt8fhj" />
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
                  >
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                          <br />
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_2"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                              }}
                            >
                              Use License
                            </span>
                          </strong>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            If you access the Marketplace Offerings via a mobile
                            application, then we grant you a revocable,
                            non-exclusive, non-transferable, limited right to
                            install and use the mobile application on wireless
                            electronic devices owned or controlled by you, and
                            to access and use the mobile application on such
                            devices strictly in accordance with the terms and
                            conditions of this mobile application license
                            contained in these Terms of Use. You shall not: (1)
                            except as permitted by applicable law, decompile,
                            reverse engineer, disassemble, attempt to derive the
                            source code of, or decrypt the application; (2) make
                            any modification, adaptation, improvement,
                            enhancement, translation, or derivative work from
                            the application; (3) violate any applicable laws,
                            rules, or regulations in connection with your access
                            or use of the application; (4) remove, alter, or
                            obscure any proprietary notice (including any notice
                            of copyright or trademark) posted by us or the
                            licensors of the application; (5) use the
                            application for any revenue generating endeavor,
                            commercial enterprise, or other purpose for which it
                            is not designed or intended; (6) make the
                            application available over a network or other
                            environment permitting access or use by multiple
                            devices or users at the same time; (7) use the
                            application for creating a product, service, or
                            software that is, directly or indirectly,
                            competitive with or in any way a substitute for the
                            application; (8) use the application to send
                            automated queries to any website or to send any
                            unsolicited commercial e-mail; or (9) use any
                            proprietary information or any of our interfaces or
                            our other intellectual property in the design,
                            development, manufacture, licensing, or distribution
                            of any applications, accessories, or devices for use
                            with the application.
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_2"
                          style={{ lineHeight: "1.5" }}
                        >
                          <a name="_vzf0b5xscg" />
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                              }}
                            >
                              Apple and Android Devices
                            </span>
                          </strong>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            The following terms apply when you use a mobile
                            application obtained from either the Apple Store or
                            Google Play (each an “App Distributor”) to access
                            the Marketplace Offerings: (1) the license granted
                            to you for our mobile application is limited to a
                            non-transferable license to use the application on a
                            device that utilizes the Apple iOS or Android
                            operating systems, as applicable, and in accordance
                            with the usage rules set forth in the applicable App
                            Distributor’s terms of service; (2) we are
                            responsible for providing any maintenance and
                            support services with respect to the mobile
                            application as specified in the terms and conditions
                            of this mobile application license contained in
                            these Terms of Use or as otherwise required under
                            applicable law, and you acknowledge that each App
                            Distributor has no obligation whatsoever to furnish
                            any maintenance and support services with respect to
                            the mobile application; (3) in the event of any
                            failure of the mobile application to conform to any
                            applicable warranty, you may notify the applicable
                            App Distributor, and the App Distributor, in
                            accordance with its terms and policies, may refund
                            the purchase price, if any, paid for the mobile
                            application, and to the maximum extent permitted by
                            applicable law, the App Distributor will have no
                            other warranty obligation whatsoever with respect to
                            the mobile application; (4) you represent and
                            warrant that (i) you are not located in a country
                            that is subject to a U.S. government embargo, or
                            that has been designated by the U.S. government as a
                            “terrorist supporting” country and (ii) you are not
                            listed on any U.S. government list of prohibited or
                            restricted parties; (5) you must comply with
                            applicable third-party terms of agreement when using
                            the mobile application, e.g., if you have a VoIP
                            application, then you must not be in violation of
                            their wireless data service agreement when using the
                            mobile application; and (6) you acknowledge and
                            agree that the App Distributors are third-party
                            beneficiaries of the terms and conditions in this
                            mobile application license contained in these Terms
                            of Use, and that each App Distributor will have the
                            right (and will be deemed to have accepted the
                            right) to enforce the terms and conditions in this
                            mobile application license contained in these Terms
                            of Use against you as a third-party beneficiary
                            thereof.
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="c954892f-02b9-c743-d1e8-faf0d59a4b70"
                  >
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    />
                  </bdt>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <a name="_608wvaau4p51" />
                  </div>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="e9981d4e-3a93-85dd-654b-7ecdf4bfe7d2"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="socialnetwork_link_option"
                        data-type="statement"
                      />{" "}
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          id="socialmedia"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              13. SOCIAL MEDIA
                            </span>
                          </strong>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            As part of the functionality of the Site, you may
                            link your account with online accounts you have with
                            third-party service providers (each such account, a
                            “Third-Party Account”) by either: (1) providing your
                            Third-Party Account login information through the
                            Site; or (2) allowing us to access your{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account, as is permitted under the applicable terms
                            and conditions that govern your use of each{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account. You represent and warrant that you are
                            entitled to disclose your{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account login information to us and/or grant us
                            access to your{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account, without breach by you of any of the terms
                            and conditions that govern your use of the
                            applicable{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account, and without obligating us to pay any fees
                            or making us subject to any usage limitations
                            imposed by the third-party service provider of the{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account. By granting us access to any{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Accounts, you understand that (1) we may access,
                            make available, and store (if applicable) any
                            content that you have provided to and stored in your{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account (the “Social Network Content”) so that it is
                            available on and through the Site via your account,
                            including without limitation any friend lists and
                            (2) we may submit to and receive from your{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account additional information to the extent you are
                            notified when you link your account with the{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account. Depending on the{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Accounts you choose and subject to the privacy
                            settings that you have set in such{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Accounts, personally identifiable information that
                            you post to your{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Accounts may be available on and through your
                            account on the Site. Please note that if a{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account or associated service becomes unavailable or
                            our access to such{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account is terminated by the third-party service
                            provider, then Social Network Content may no longer
                            be available on and through the Site. You will have
                            the ability to disable the connection between your
                            account on the Site and your{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Accounts at any time. PLEASE NOTE THAT YOUR
                            RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDERS
                            ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNTS IS
                            GOVERNED SOLELY BY YOUR AGREEMENT(S) WITH SUCH
                            THIRD-PARTY SERVICE PROVIDERS. We make no effort to
                            review any Social Network Content for any purpose,
                            including but not limited to, for accuracy,
                            legality, or non-infringement, and we are not
                            responsible for any Social Network Content. You
                            acknowledge and agree that we may access your email
                            address book associated with a{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account and your contacts list stored on your mobile
                            device or tablet computer solely for purposes of
                            identifying and informing you of those contacts who
                            have also registered to use the Site. You can
                            deactivate the connection between the Site and your{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account by contacting us using the contact
                            information below or through your account settings
                            (if applicable). We will attempt to delete any
                            information stored on our servers that was obtained
                            through such{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Account, except the username and profile picture
                            that become associated with your account.
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="e9981d4e-3a93-85dd-654b-7ecdf4bfe7d2"
                  >
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    />
                  </bdt>
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="submissions"
                    style={{ lineHeight: "1.5" }}
                  >
                    <a name="_wfq2hvrw11j4" />
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        14. SUBMISSIONS
                      </span>
                    </strong>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      You acknowledge and agree that any questions, comments,
                      suggestions, ideas, feedback, or other information
                      regarding the Site or the Marketplace Offerings
                      ("Submissions") provided by you to us are non-confidential
                      and shall become our sole property. We shall own exclusive
                      rights, including all intellectual property rights, and
                      shall be entitled to the unrestricted use and
                      dissemination of these Submissions for any lawful purpose,
                      commercial or otherwise, without acknowledgment or
                      compensation to you. You hereby waive all moral rights to
                      any such Submissions, and you hereby warrant that any such
                      Submissions are original with you or that you have the
                      right to submit such Submissions. You agree there shall be
                      no recourse against us for any alleged or actual
                      infringement or misappropriation of any proprietary right
                      in your Submissions.
                    </span>
                  </div>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <a name="_np5tpdc7g5vt" />
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="3rd_party_option"
                        data-type="statement"
                      />
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          id="thirdparty"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              15. THIRD-PARTY WEBSITES AND CONTENT
                            </span>
                          </strong>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            The Site may contain (or you may be sent via the
                            Site or the Marketplace Offerings) links to other
                            websites ("
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Websites") as well as articles, photographs, text,
                            graphics, pictures, designs, music, sound, video,
                            information, applications, software, and other
                            content or items belonging to or originating from
                            third parties ("Third-Party Content"). Such{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Websites and{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Content are not investigated, monitored, or checked
                            for accuracy, appropriateness, or completeness by
                            us, and we are not responsible for any Third Party
                            Websites accessed through the Site or any{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Content posted on, available through, or installed
                            from the Site, including the content, accuracy,
                            offensiveness, opinions, reliability, privacy
                            practices, or other policies of or contained in the{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Websites or the{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Content. Inclusion of, linking to, or permitting the
                            use or installation of any{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Websites or any{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party{" "}
                            </span>
                            Content does not imply approval or endorsement
                            thereof by us. If you decide to leave the Site and
                            access the{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Websites or to use or install any{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Content, you do so at your own risk, and you should
                            be aware these Terms of Use no longer govern. You
                            should review the applicable terms and policies,
                            including privacy and data gathering practices, of
                            any website to which you navigate from the Site or
                            relating to any applications you use or install from
                            the Site. Any purchases you make through{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Websites will be through other websites and from
                            other companies, and we take no responsibility
                            whatsoever in relation to such purchases which are
                            exclusively between you and the applicable third
                            party. You agree and acknowledge that we do not
                            endorse the products or services offered on{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Websites and you shall hold us harmless from any
                            harm caused by your purchase of such products or
                            services. Additionally, you shall hold us harmless
                            from any losses sustained by you or harm caused to
                            you relating to or resulting in any way from any{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Content or any contact with{" "}
                            <span style={{ fontSize: "14.6667px" }}>
                              Third-Party
                            </span>{" "}
                            Websites.
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-container if" data-type="if">
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    />
                  </bdt>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <a name="_29ce8o9pbtmi" />
                  </div>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="14038561-dad7-be9d-370f-f8aa487b2570"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="advertiser_option"
                        data-type="statement"
                      />
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          id="advertisers"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              16. ADVERTISERS
                            </span>
                          </strong>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-container if" data-type="if">
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            We allow advertisers to display their advertisements
                            and other information in certain areas of the Site,
                            such as sidebar advertisements or banner
                            advertisements. If you are an advertiser, you shall
                            take full responsibility for any advertisements you
                            place on the Site and any services provided on the
                            Site or products sold through those advertisements.
                            Further, as an advertiser, you warrant and represent
                            that you possess all rights and authority to place
                            advertisements on the Site, including, but not
                            limited to, intellectual property rights, publicity
                            rights, and contractual rights.{" "}
                            <bdt className="block-component" />
                            We simply provide the space to place such
                            advertisements, and we have no other relationship
                            with advertisers.
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="14038561-dad7-be9d-370f-f8aa487b2570"
                  >
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    />
                  </bdt>
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="sitemanage"
                    style={{ lineHeight: "1.5" }}
                  >
                    <a name="_wj13r09u8u3u" />
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        17. SITE MANAGEMENT
                      </span>
                    </strong>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      We reserve the right, but not the obligation, to: (1)
                      monitor the Site for violations of these Terms of Use; (2)
                      take appropriate legal action against anyone who, in our
                      sole discretion, violates the law or these Terms of Use,
                      including without limitation, reporting such user to law
                      enforcement authorities; (3) in our sole discretion and
                      without limitation, refuse, restrict access to, limit the
                      availability of, or disable (to the extent technologically
                      feasible) any of your Contributions or any portion
                      thereof; (4) in our sole discretion and without
                      limitation, notice, or liability, to remove from the Site
                      or otherwise disable all files and content that are
                      excessive in size or are in any way burdensome to our
                      systems; and (5) otherwise manage the Site in a manner
                      designed to protect our rights and property and to
                      facilitate the proper functioning of the Site and the
                      Marketplace Offerings.
                    </span>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                  </div>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <a name="_jugvcvcw0oj9" />
                  </div>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="bdd90fa9-e664-7d0b-c352-2b8e77dd3bb4"
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="privacy_policy_option"
                        data-type="statement"
                      />{" "}
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_1"
                          id="privacypolicy1"
                          style={{ lineHeight: "1.5" }}
                        >
                          <strong>
                            <span
                              style={{
                                lineHeight: "115%",
                                fontFamily: "Arial",
                                fontSize: "19px",
                              }}
                            >
                              18. PRIVACY POLICY
                            </span>
                          </strong>
                        </div>
                        <div className="MsoNormal" style={{ lineHeight: 1 }}>
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            <br />
                          </span>
                        </div>
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11.0pt",
                              lineHeight: "115%",
                              fontFamily: "Arial",
                              color: "#595959",
                              msoThemecolor: "text1",
                              msoThemetint: 166,
                            }}
                          >
                            We care about data privacy and security. Please
                            review our Privacy Policy:{" "}
                            <strong>
                              <bdt
                                className="block-container question question-in-editor"
                                data-id="d10c7fd7-0685-12ac-c717-cbc45ff916d1"
                                data-type="question"
                              >
                                <a
                                  href="https://app.termly.io/dashboard/website/e69698b8-9f6a-4566-9e62-e4d2f8d938be"
                                  target="_blank"
                                  data-custom-class="link"
                                >
                                  https://app.termly.io/dashboard/website/e69698b8-9f6a-4566-9e62-e4d2f8d938be
                                </a>
                              </bdt>
                            </strong>
                            . By using the Site or the Marketplace Offerings,
                            you agree to be bound by our Privacy Policy, which
                            is incorporated into these Terms of Use. Please be
                            advised the Site and the Marketplace Offerings are
                            hosted in{" "}
                            <span
                              style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <bdt className="block-component" />
                              the <bdt className="question">United States</bdt>
                              <bdt className="block-component" />
                            </span>
                            <bdt className="block-component" />. If you access
                            the Site or the Marketplace Offerings from any other
                            region of the world with laws or other requirements
                            governing personal data collection, use, or
                            disclosure that differ from applicable laws in{" "}
                            <span
                              style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <bdt className="block-component" />
                              the <bdt className="question">United States</bdt>
                              <bdt className="block-component" />
                            </span>
                            <bdt className="block-component" />, then through
                            your continued use of the Site, you are transferring
                            your data to{" "}
                            <span
                              style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <bdt className="block-component" />
                              the <bdt className="question">United States</bdt>
                              <bdt className="block-component" />
                            </span>
                            <bdt className="block-component" />, and you agree
                            to have your data transferred to and processed in{" "}
                            <span
                              style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <bdt className="block-component" />
                              the <bdt className="question">United States</bdt>
                              <bdt className="block-component" />
                            </span>
                            <bdt className="block-component" />.{" "}
                            <span
                              style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="bdd90fa9-e664-7d0b-c352-2b8e77dd3bb4"
                  >
                    <bdt
                      className="statement-end-if-in-editor"
                      data-type="close"
                    />
                  </bdt>
                  <div style={{ textAlign: "justify" }}>
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="d9392bd6-1e22-6553-dbab-8d5310cff0d5"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="privacy_policy_folowup"
                          data-type="statement"
                          style={{ fontSize: "14.6667px" }}
                        />
                      </bdt>
                    </bdt>
                  </div>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <a name="_n081pott8yce" />
                  </div>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    style={{ textAlign: "left" }}
                  >
                    <bdt data-type="conditional-block">
                      <bdt
                        className="block-component"
                        data-record-question-key="copyright_agent_option"
                        data-type="statement"
                      >
                        <bdt className="block-component" />
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <bdt className="block-component" />
                  <bdt className="block-component" />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="term"
                    style={{ lineHeight: "1.5" }}
                  >
                    <a name="_k3mndam4w6w1" />
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        19. TERM AND TERMINATION
                      </span>
                    </strong>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      These Terms of Use shall remain in full force and effect
                      while you use the Site. WITHOUT LIMITING ANY OTHER
                      PROVISION OF THESE TERMS OF USE, WE RESERVE THE RIGHT TO,
                      IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY,
                      DENY ACCESS TO AND USE OF THE SITE AND THE MARKETPLACE
                      OFFERINGS (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO
                      ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING
                      WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION,
                      WARRANTY, OR COVENANT CONTAINED IN THESE TERMS OF USE OR
                      OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR
                      USE OR PARTICIPATION IN THE SITE AND THE MARKETPLACE
                      OFFERINGS OR DELETE{" "}
                      <bdt
                        className="block-container if"
                        data-type="if"
                        id="a6e121c2-36b4-5066-bf9f-a0a33512e768"
                      >
                        <bdt data-type="conditional-block">
                          <bdt
                            className="block-component"
                            data-record-question-key="user_account_option"
                            data-type="statement"
                          />
                          <bdt data-type="body">YOUR ACCOUNT AND </bdt>
                        </bdt>
                        <bdt
                          className="statement-end-if-in-editor"
                          data-type="close"
                        />
                      </bdt>
                      ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME,
                      WITHOUT WARNING, IN OUR SOLE DISCRETION.
                    </span>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      If we terminate or suspend your account for any reason,
                      you are prohibited from registering and creating a new
                      account under your name, a fake or borrowed name, or the
                      name of any third party, even if you may be acting on
                      behalf of the third party. In addition to terminating or
                      suspending your account, we reserve the right to take
                      appropriate legal action, including without limitation
                      pursuing civil, criminal, and injunctive redress.
                    </span>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                  </div>
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="modifications"
                    style={{ lineHeight: "1.5" }}
                  >
                    <a name="_e2dep1hfgltt" />
                    <strong>
                      <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                        <span style={{ fontSize: "19px" }}>
                          20. MODIFICATIONS AND INTERRUPTIONS
                        </span>
                      </span>
                    </strong>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      We reserve the right to change, modify, or remove the
                      contents of the Site at any time or for any reason at our
                      sole discretion without notice. However, we have no
                      obligation to update any information on our Site. We also
                      reserve the right to modify or discontinue all or part of
                      the Marketplace Offerings without notice at any time. We
                      will not be liable to you or any third party for any
                      modification, price change, suspension, or discontinuance
                      of the Site or the Marketplace Offerings.
                    </span>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      We cannot guarantee the Site and the Marketplace Offerings
                      will be available at all times. We may experience
                      hardware, software, or other problems or need to perform
                      maintenance related to the Site, resulting in
                      interruptions, delays, or errors. We reserve the right to
                      change, revise, update, suspend, discontinue, or otherwise
                      modify the Site or the Marketplace Offerings at any time
                      or for any reason without notice to you. You agree that we
                      have no liability whatsoever for any loss, damage, or
                      inconvenience caused by your inability to access or use
                      the Site or the Marketplace Offerings during any downtime
                      or discontinuance of the Site or the Marketplace
                      Offerings. Nothing in these Terms of Use will be construed
                      to obligate us to maintain and support the Site or the
                      Marketplace Offerings or to supply any corrections,
                      updates, or releases in connection therewith.
                    </span>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                  </div>
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="law"
                    style={{ lineHeight: "1.5" }}
                  >
                    <a name="_p6vbf8atcwhs" />
                    <strong>
                      <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                        <span style={{ fontSize: "19px" }}>
                          21. GOVERNING LAW
                        </span>
                      </span>
                    </strong>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      <bdt className="block-component" />
                    </span>
                  </div>
                  <div
                    className="MsoNormal"
                    data-custom-class="body_text"
                    style={{ lineHeight: "1.5" }}
                  >
                    <span
                      style={{
                        fontSize: "11.0pt",
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        color: "#595959",
                        msoThemecolor: "text1",
                        msoThemetint: 166,
                      }}
                    >
                      These Terms of Use and your use of the Site and the
                      Marketplace Offerings are governed by and construed in
                      accordance with the laws of{" "}
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <bdt
                          className="block-container if"
                          data-type="if"
                          id="b86653c1-52f0-c88c-a218-e300b912dd6b"
                        >
                          <bdt data-type="conditional-block">
                            <bdt
                              className="block-component"
                              data-record-question-key="governing_law"
                              data-type="statement"
                            />
                            <bdt data-type="body">
                              the State of{" "}
                              <bdt
                                className="block-container question question-in-editor"
                                data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3"
                                data-type="question"
                              >
                                Delaware
                              </bdt>
                            </bdt>
                          </bdt>
                          <bdt
                            className="statement-end-if-in-editor"
                            data-type="close"
                          />
                        </bdt>{" "}
                        applicable to agreements made and to be entirely
                        performed within
                        <span style={{ fontSize: "14.6667px" }}> </span>
                        <span
                          style={{
                            fontSize: "11pt",
                            lineHeight: "16.8667px",
                            color: "rgb(89, 89, 89)",
                          }}
                        >
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="b86653c1-52f0-c88c-a218-e300b912dd6b"
                            style={{ fontSize: "14.6667px" }}
                          >
                            <bdt data-type="conditional-block">
                              <span
                                style={{
                                  fontSize: "11pt",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <bdt
                                  className="block-container if"
                                  data-type="if"
                                  id="b86653c1-52f0-c88c-a218-e300b912dd6b"
                                >
                                  <bdt data-type="conditional-block">
                                    <bdt
                                      className="block-component"
                                      data-record-question-key="governing_law"
                                      data-type="statement"
                                    />
                                    <bdt data-type="body">
                                      the State of{" "}
                                      <bdt
                                        className="block-container question question-in-editor"
                                        data-id="b61250bd-6b61-32ea-a9e7-4a02690297c3"
                                        data-type="question"
                                      >
                                        Delaware
                                      </bdt>
                                    </bdt>
                                  </bdt>
                                  <bdt
                                    className="statement-end-if-in-editor"
                                    data-type="close"
                                  />
                                </bdt>
                                <span style={{ fontSize: "14.6667px" }}>
                                  ,{" "}
                                </span>
                                without regard to its conflict of law
                                principles.
                                <bdt className="block-component" />
                              </span>
                            </bdt>
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div className="MsoNormal" style={{ lineHeight: "1.5" }}>
                    <br />
                  </div>
                  <div
                    className="MsoNormal"
                    data-custom-class="heading_1"
                    id="disputes"
                    style={{ lineHeight: "1.5" }}
                  >
                    <a name="_v5i5tjw62cyw" />
                    <strong>
                      <span
                        style={{
                          lineHeight: "115%",
                          fontFamily: "Arial",
                          fontSize: "19px",
                        }}
                      >
                        22. DISPUTE RESOLUTION
                      </span>
                    </strong>
                  </div>
                </div>
                <div
                  align="center"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div align="center" style={{ textAlign: "left" }}>
                  <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="4de367b8-a92e-8bf8-bc2e-013cba6337f8"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="dispute_option"
                          data-type="statement"
                        />
                      </bdt>
                    </bdt>
                  </div>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="4de367b8-a92e-8bf8-bc2e-013cba6337f8"
                  >
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          style={{ textAlign: "left", lineHeight: "1.5" }}
                        >
                          <span
                            style={{
                              fontSize: "11pt",
                              lineHeight: "16.8667px",
                              color: "rgb(89, 89, 89)",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <bdt className="block-component" />
                            </span>
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="4de367b8-a92e-8bf8-bc2e-013cba6337f8"
                  >
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="heading_2"
                          style={{ textAlign: "left", lineHeight: "1.5" }}
                        >
                          <strong>Informal Negotiations</strong>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div className="MsoNormal" style={{ lineHeight: 1 }}>
                  <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="4de367b8-a92e-8bf8-bc2e-013cba6337f8"
                  >
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          data-custom-class="body_text"
                          style={{ textAlign: "left", lineHeight: "1.5" }}
                        >
                          <span style={{ fontSize: "15px" }}>
                            To expedite resolution and control the cost of any
                            dispute, controversy, or claim related to these
                            Terms of Use (each "Dispute" and collectively, the
                            "Disputes") brought by either you or us
                            (individually, a "Party" and collectively, the
                            "Parties"), the Parties agree to first attempt to
                            negotiate any Dispute (except those Disputes
                            expressly provided below) informally for at least{" "}
                            <bdt className="question">ninety (90)</bdt> days
                            before initiating arbitration. Such informal
                            negotiations commence upon written notice from one
                            Party to the other Party.
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div className="MsoNormal" style={{ lineHeight: 1 }}>
                  <br />
                </div>
                <div className="MsoNormal" style={{ lineHeight: "115%" }}>
                  <bdt
                    className="block-container if"
                    data-type="if"
                    id="4de367b8-a92e-8bf8-bc2e-013cba6337f8"
                  >
                    <bdt data-type="conditional-block">
                      <bdt data-type="body">
                        <div
                          className="MsoNormal"
                          style={{ textAlign: "left", lineHeight: "1.5" }}
                        >
                          <span style={{ fontSize: "15px" }}>
                            <span
                              style={{
                                fontSize: "11pt",
                                lineHeight: "16.8667px",
                                color: "rgb(89, 89, 89)",
                              }}
                            >
                              <span
                                style={{
                                  fontSize: "11pt",
                                  lineHeight: "16.8667px",
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </span>
                        </div>
                      </bdt>
                    </bdt>
                  </bdt>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_2"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>Binding Arbitration</strong>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-component">
                    <span style={{ fontSize: "15px" }} />
                  </bdt>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: "15px" }}>
                    Any dispute arising from the relationships between the
                    Parties to this contract shall be determined by one
                    arbitrator who will be chosen in accordance with the
                    Arbitration and Internal Rules of the European Court of
                    Arbitration being part of the European Centre of Arbitration
                    having its seat in Strasbourg, and which are in force at the
                    time the application for arbitration is filed, and of which
                    adoption of this clause constitutes acceptance. The seat of
                    arbitration shall be <bdt className="block-component" />
                    <bdt className="question">HAgue</bdt>,{" "}
                    <bdt className="question">Netherlands</bdt>
                    <bdt className="else-block" />. The language of the
                    proceedings shall be <bdt className="question">English</bdt>
                    . Applicable rules of substantive law shall be the law of{" "}
                    <span style={{ fontSize: "15px" }}>
                      <bdt className="block-component" />
                      the <bdt className="question">Netherlands</bdt>
                      <bdt className="else-block" />
                    </span>
                  </span>
                  .<bdt className="else-block" />{" "}
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_2"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>Restrictions</strong>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: "15px" }}>
                    The Parties agree that any arbitration shall be limited to
                    the Dispute between the Parties individually. To the full
                    extent permitted by law, (a) no arbitration shall be joined
                    with any other proceeding; (b) there is no right or
                    authority for any Dispute to be arbitrated on a class-action
                    basis or to utilize class action procedures; and (c) there
                    is no right or authority for any Dispute to be brought in a
                    purported representative capacity on behalf of the general
                    public or any other persons.
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_2"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <bdt className="block-component">
                    <strong />
                  </bdt>
                  <strong>
                    Exceptions to Informal Negotiations and Arbitration
                    <bdt className="statement-end-if-in-editor" />
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: "15px" }}>
                    <bdt className="block-component" />
                    The Parties agree that the following Disputes are not
                    subject to the above provisions concerning informal
                    negotiations and binding arbitration: (a) any Disputes
                    seeking to enforce or protect, or concerning the validity
                    of, any of the intellectual property rights of a Party; (b)
                    any Dispute related to, or arising from, allegations of
                    theft, piracy, invasion of privacy, or unauthorized use; and
                    (c) any claim for injunctive relief. If this provision is
                    found to be illegal or unenforceable, then neither Party
                    will elect to arbitrate any Dispute falling within that
                    portion of this provision found to be illegal or
                    unenforceable and such Dispute shall be decided by a court
                    of competent jurisdiction within the courts listed for
                    jurisdiction above, and the Parties agree to submit to the
                    personal jurisdiction of that court.
                    <span style={{ fontSize: "15px" }}>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span style={{ fontSize: "15px" }}>
                    <span
                      style={{
                        fontSize: "11pt",
                        lineHeight: "16.8667px",
                        color: "rgb(89, 89, 89)",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "11pt",
                          lineHeight: "16.8667px",
                          color: "rgb(89, 89, 89)",
                        }}
                      >
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="corrections"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>23. CORRECTIONS</strong>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    There may be information on the Site that contains
                    typographical errors, inaccuracies, or omissions that may
                    relate to the Marketplace Offerings, including descriptions,
                    pricing, availability, and various other information. We
                    reserve the right to correct any errors, inaccuracies, or
                    omissions and to change or update the information on the
                    Site at any time, without prior notice.
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="disclaimer"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      24. DISCLAIMER
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    THE SITE AND THE MARKETPLACE OFFERINGS ARE PROVIDED ON AN
                    AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE
                    SITE AND OUR SERVICES WILL BE AT YOUR SOLE RISK. TO THE
                    FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES,
                    EXPRESS OR IMPLIED, IN CONNECTION WITH THE SITE AND THE
                    MARKETPLACE OFFERINGS AND YOUR USE THEREOF, INCLUDING,
                    WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                    NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS
                    ABOUT THE ACCURACY OR COMPLETENESS OF THE SITE’S CONTENT OR
                    THE CONTENT OF ANY WEBSITES LINKED TO THE SITE AND WE WILL
                    ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS,
                    MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2)
                    PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE
                    WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE
                    SITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
                    SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
                    FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION
                    OR CESSATION OF TRANSMISSION TO OR FROM THE SITE OR THE
                    MARKETPLACE OFFERINGS, (5) ANY BUGS, VIRUSES, TROJAN HORSES,
                    OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SITE
                    BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN
                    ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY
                    KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED,
                    TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SITE. WE DO
                    NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY
                    FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD
                    PARTY THROUGH THE SITE, ANY HYPERLINKED WEBSITE, OR ANY
                    WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR
                    OTHER ADVERTISING, AND WE WILL NOT BE A PARTY TO OR IN ANY
                    WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN
                    YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                    AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY
                    MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                    JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="liability"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      25. LIMITATIONS OF LIABILITY
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS
                    BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT,
                    INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR
                    PUNITIVE DAMAGES, INCLUDING LOST PROFIT, LOST REVENUE, LOSS
                    OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SITE
                    OR THE MARKETPLACE OFFERINGS, EVEN IF WE HAVE BEEN ADVISED
                    OF THE POSSIBILITY OF SUCH DAMAGES.{" "}
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="3c3071ce-c603-4812-b8ca-ac40b91b9943"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="limitations_liability_option"
                          data-type="statement"
                        />
                        <bdt data-type="body">
                          NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED
                          HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER
                          AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL
                          TIMES BE LIMITED TO{" "}
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="73189d93-ed3a-d597-3efc-15956fa8e04e"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="limitations_liability_option"
                                data-type="statement"
                              />
                              <bdt data-type="body">
                                THE LESSER OF THE AMOUNT PAID, IF ANY, BY YOU TO
                                US
                                <bdt data-type="conditional-block">
                                  {" "}
                                  <bdt
                                    className="block-component"
                                    data-record-question-key="limilation_liability_time_option"
                                    data-type="statement"
                                  />{" "}
                                  <bdt data-type="body">
                                    <span
                                      style={{
                                        fontSize: "11pt",
                                        color: "rgb(89, 89, 89)",
                                        textTransform: "uppercase",
                                      }}
                                    >
                                      DURING THE{" "}
                                      <bdt
                                        className="block-container question question-in-editor"
                                        data-id="98461079-8520-8e55-edbd-84fdc37a26d4"
                                        data-type="question"
                                      >
                                        one (1)
                                      </bdt>{" "}
                                      MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION
                                      ARISING
                                    </span>
                                  </bdt>
                                </bdt>
                                <bdt
                                  className="statement-end-if-in-editor"
                                  data-type="close"
                                />{" "}
                                OR{" "}
                                <bdt
                                  className="block-container question question-in-editor"
                                  data-id="243ad246-9e92-b24d-beee-940be6aa7854"
                                  data-type="question"
                                >
                                  $100.00 USD
                                </bdt>
                              </bdt>
                            </bdt>
                            <bdt
                              className="statement-end-if-in-editor"
                              data-type="close"
                            />
                          </bdt>
                          . CERTAIN US STATE LAWS AND INTERNATIONAL LAWS DO NOT
                          ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE
                          EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE
                          LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE
                          DISCLAIMERS OR LIMITATIONS MAY NOT APPLY TO YOU, AND
                          YOU MAY HAVE ADDITIONAL RIGHTS.
                        </bdt>
                      </bdt>
                      <bdt
                        className="statement-end-if-in-editor"
                        data-type="close"
                      />
                    </bdt>
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="indemnification"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      26. INDEMNIFICATION
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    You agree to defend, indemnify, and hold us harmless,
                    including our subsidiaries, affiliates, and all of our
                    respective officers, agents, partners, and employees, from
                    and against any loss, damage, liability, claim, or demand,
                    including reasonable attorneys’ fees and expenses, made by
                    any third party due to or arising out of:{" "}
                    <bdt
                      className="block-container if"
                      data-type="if"
                      id="475fffa5-05ca-def8-ac88-f426b238903c"
                    >
                      <bdt data-type="conditional-block">
                        <bdt
                          className="block-component"
                          data-record-question-key="user_post_content_option"
                          data-type="statement"
                        />
                      </bdt>
                      (<span style={{ fontSize: "14.6667px" }}>1</span>) use of
                      the Marketplace Offerings; (
                      <span style={{ fontSize: "14.6667px" }}>2</span>) breach
                      of these Terms of Use; (
                      <span style={{ fontSize: "14.6667px" }}>3</span>) any
                      breach of your representations and warranties set forth in
                      these Terms of Use; (
                      <span style={{ fontSize: "14.6667px" }}>4</span>) your
                      violation of the rights of a third party, including but
                      not limited to intellectual property rights; or (
                      <span style={{ fontSize: "14.6667px" }}>5</span>) any
                      overt harmful act toward any other user of the Site or the
                      Marketplace Offerings with whom you connected via the
                      Site. Notwithstanding the foregoing, we reserve the right,
                      at your expense, to assume the exclusive defense and
                      control of any matter for which you are required to
                      indemnify us, and you agree to cooperate, at your expense,
                      with our defense of such claims. We will use reasonable
                      efforts to notify you of any such claim, action, or
                      proceeding which is subject to this indemnification upon
                      becoming aware of it.
                    </bdt>
                  </span>
                  <span
                    style={{ color: "rgb(89, 89, 89)", fontSize: "14.6667px" }}
                  />
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="userdata"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      27. USER DATA
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    We will maintain certain data that you transmit to the Site
                    for the purpose of managing the performance of the
                    Marketplace Offerings, as well as data relating to your use
                    of the Marketplace Offerings. Although we perform regular
                    routine backups of data, you are solely responsible for all
                    data that you transmit or that relates to any activity you
                    have undertaken using the Marketplace Offerings. You agree
                    that we shall have no liability to you for any loss or
                    corruption of any such data, and you hereby waive any right
                    of action against us arising from any such loss or
                    corruption of such data.
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="electronic"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      28. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                      SIGNATURES
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    Visiting the Site, sending us emails, and completing online
                    forms constitute electronic communications. You consent to
                    receive electronic communications, and you agree that all
                    agreements, notices, disclosures, and other communications
                    we provide to you electronically, via email and on the Site,
                    satisfy any legal requirement that such communication be in
                    writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC
                    SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO
                    ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF
                    TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SITE.
                    You hereby waive any rights or requirements under any
                    statutes, regulations, rules, ordinances, or other laws in
                    any jurisdiction which require an original signature or
                    delivery or retention of non-electronic records, or to
                    payments or the granting of credits by any means other than
                    electronic means.
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    <bdt className="block-component" />
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="misc"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      style={{
                        lineHeight: "115%",
                        fontFamily: "Arial",
                        fontSize: "19px",
                      }}
                    >
                      29. MISCELLANEOUS
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    These Terms of Use and any policies or operating rules
                    posted by us on the Site or in respect to the Marketplace
                    Offerings constitute the entire agreement and understanding
                    between you and us. Our failure to exercise or enforce any
                    right or provision of these Terms of Use shall not operate
                    as a waiver of such right or provision. These Terms of Use
                    operate to the fullest extent permissible by law. We may
                    assign any or all of our rights and obligations to others at
                    any time. We shall not be responsible or liable for any
                    loss, damage, delay, or failure to act caused by any cause
                    beyond our reasonable control. If any provision or part of a
                    provision of these Terms of Use is determined to be
                    unlawful, void, or unenforceable, that provision or part of
                    the provision is deemed severable from these Terms of Use
                    and does not affect the validity and enforceability of any
                    remaining provisions. There is no joint venture,
                    partnership, employment or agency relationship created
                    between you and us as a result of these Terms of Use or use
                    of the Marketplace Offerings. You agree that these Terms of
                    Use will not be construed against us by virtue of having
                    drafted them. You hereby waive any and all defenses you may
                    have based on the electronic form of these Terms of Use and
                    the lack of signing by the parties hereto to execute these
                    Terms of Use.
                    <bdt className="block-component" />
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="heading_1"
                  id="contact"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <strong>
                    <span style={{ lineHeight: "115%", fontFamily: "Arial" }}>
                      <span style={{ fontSize: "19px" }}>30. CONTACT US</span>
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "11.0pt",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "#595959",
                      msoThemecolor: "text1",
                      msoThemetint: 166,
                    }}
                  >
                    In order to resolve a complaint regarding the Site or the
                    Marketplace Offerings or to receive further information
                    regarding use of the Site or the Marketplace Offerings,
                    please contact us at:
                  </span>
                </div>
                <div
                  className="MsoNormal"
                  style={{ textAlign: "left", lineHeight: 1 }}
                >
                  <br />
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ textAlign: "left", lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <bdt
                      className="block-container question question-in-editor"
                      data-id="8a6919c4-2010-e7d6-2305-d74dfb08909d"
                      data-type="question"
                    >
                      <strong>INNOVATION LAB (PVT) LIMTIED</strong>
                    </bdt>
                  </span>
                  <strong>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <bdt className="block-component" />
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      <bdt className="question">
                        G-3, Jouhar belle view apartment, block 14, Gulistan e
                        jouhar
                      </bdt>
                      <bdt className="statement-end-if-in-editor" />
                    </span>
                  </strong>
                  <strong>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <strong>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <bdt className="question">Karachi</bdt>
                        <bdt className="block-component" />,{" "}
                        <bdt className="question">Sindh</bdt>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </strong>
                  <strong>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </strong>
                  <strong>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <bdt className="block-component" />
                        <bdt className="block-component" />
                      </span>
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <bdt className="block-component">
                    <strong />
                  </bdt>
                  <strong>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <bdt className="question">Pakistan</bdt>
                        <bdt className="statement-end-if-in-editor" />
                      </span>
                    </span>
                  </strong>
                  <strong>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <bdt className="statement-end-if-in-editor">
                          <strong>
                            <span style={{ color: "rgb(89, 89, 89)" }}>
                              <span style={{ fontSize: "15px" }}>
                                <bdt className="statement-end-if-in-editor" />
                              </span>
                            </span>
                          </strong>
                        </bdt>
                      </span>
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <strong>
                    <span
                      style={{ fontSize: "15px", color: "rgb(89, 89, 89)" }}
                    >
                      Phone:
                    </span>{" "}
                  </strong>
                  <strong>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <span
                          style={{ lineHeight: "115%", fontFamily: "Arial" }}
                        >
                          <bdt
                            className="block-container question question-in-editor"
                            data-id="dd6f266f-438b-bfdc-9204-0b17e109e270"
                            data-type="question"
                          >
                            (+92)3335474445
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <strong>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: "15px" }}>
                        <span
                          style={{ lineHeight: "115%", fontFamily: "Arial" }}
                        >
                          <bdt
                            className="block-container if"
                            data-type="if"
                            id="c2a2342b-7ccc-246b-45ba-33ad54da2b12"
                          >
                            <bdt data-type="conditional-block">
                              <bdt
                                className="block-component"
                                data-record-question-key="company_fax"
                                data-type="statement"
                              />
                            </bdt>
                          </bdt>
                        </span>
                      </span>
                    </span>
                  </strong>
                </div>
                <div
                  className="MsoNormal"
                  data-custom-class="body_text"
                  style={{ lineHeight: "1.5" }}
                >
                  <span
                    style={{
                      fontSize: "15px",
                      lineHeight: "115%",
                      fontFamily: "Arial",
                      color: "rgb(89, 89, 89)",
                    }}
                  >
                    <bdt
                      className="block-container question question-in-editor"
                      data-id="fdc2b5b8-c95f-244b-23a7-287f82541348"
                      data-type="question"
                    >
                      <strong>a.ahmad@eblink.io</strong>
                    </bdt>
                  </span>
                </div>
              </bdt>
            </bdt>
          </bdt>
        </div>
      </div>
    </Container>
  );
};
export default TermsAndConditionsV2;
