import logo from "./logo.svg";
import "./App.css";
import HeaderAndCarousel from "./Components/Carousel";
import CarCarousel from "./Components/Car-Carousel";
import ServicesDescriptions from "./Components/Services-descriptions";
import { DriveWithBlink } from "./Components/Drive-with-blink";
import Footer from "./Components/Footer";
import { useState } from "react";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import TermsAndConditions from "./Components/terms-and-conditions";
import PrivacyPolicy from "./Components/privacy-policy";
import PrivacyPolicy2 from "./Components/privacy-policy-v2";
import Cookies from "./Components/Cookies";
import TermsAndConditionsV2 from "./Components/terms-and-conditions-V2";
function App() {
  const [language, setLanguage] = useState(1); //1 for english  2 for urdu
  return (
    <Router>
      <Route
        exact
        path="/"
        render={(props) => (
          <div className="blink_container">
            <HeaderAndCarousel
              activeLanguage={language}
              handleLanguageChange={(language) => setLanguage(language)}
            />
            <CarCarousel activeLanguage={language} />
            <ServicesDescriptions activeLanguage={language} />
            <DriveWithBlink activeLanguage={language} />
            <Footer activeLanguage={language} {...props} />
          </div>
        )}
      />

      <Route exact path="/terms" render={() => <TermsAndConditions />} />
      <Route exact path="/privacy" component={() => <PrivacyPolicy />} />
      <Route exact path="/privacy-v2" component={() => <PrivacyPolicy2 />} />
      <Route exact path="/cookies" component={() => <Cookies />} />
      <Route
        exact
        path="/terms-v2"
        component={() => <TermsAndConditionsV2 />}
      />
    </Router>
  );
}

export default App;
